import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-merchant-menu-bar',
  templateUrl: './merchant-menu-bar.component.html',
  styleUrls: ['./merchant-menu-bar.component.css']
})
export class MerchantMenuBarComponent implements OnInit {

  constructor() { }

  items: MenuItem[];

    ngOnInit() {
        this.items = [
            {label: 'Manage Menu', routerLink: "/menu-items"},
            {label: 'Manage Categories', routerLink: "/menu-categories"},
            {label: 'Manage Tables', routerLink: "/tables"},
            {label: 'Manage Waiters', routerLink: "/waiters"},
            {label: 'Orders', routerLink: "/orders"}
            
        ];
    }

}
