import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver/dist/Filesaver'

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {


  private baseUrl = 'https://api.bludin.com/qrCodeApi';
 // private baseUrl = 'http://localhost:8000/qrCodeApi';
  constructor(private http:HttpClient) { }

  
  downloadAllPdfs(restaurantId:number){    
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/pdf');
    this.http.get(`${this.baseUrl}/qrCodePDF/${restaurantId}`, { headers: headers, responseType: 'blob' as 'json' })
      .toPromise()
      .then(response => this.saveToFileSystem(response))
      .catch();
   }

  saveFile(restaurantId:number, tableNumber?:any) {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/pdf');
    this.http.get(`${this.baseUrl}/qrCodePDF/${restaurantId}/${tableNumber}`, { headers: headers, responseType: 'blob' as 'json' })
      .toPromise()
      .then(response => this.saveToFileSystem(response))
      .catch();
  }
 
  private saveToFileSystem(response) {
    //const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    //const parts: string[] = contentDispositionHeader.split(';');
   // const filename = parts[1].split('=')[1];
    const blob = new Blob([response], { type: 'application/pdf' });
    saveAs(blob, "tabel.pdf");
  }
  
}
