import { Component, OnInit } from '@angular/core';
import { Table } from '../../models/table.model';
import { TableService } from '../../services/table.service';
import { WaiterService } from '../../services/waiter.service';
import { Waiter } from '../../models/waiter.model';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
//import * as jsPDF from "jspdf";
import { MyAppLoadService } from 'src/app/myapp-load.service';
import { PdfGeneratorService } from '../../services/pdf-generator.service';


@Component({
  selector: 'app-manage-tables',
  templateUrl: './manage-tables.component.html',
  styleUrls: ['./manage-tables.component.css']
})
export class ManageTablesComponent implements OnInit {

  constructor(private waiterService: WaiterService, private tableService: TableService, private appLoadService: MyAppLoadService, private pdfDownloadService:PdfGeneratorService) { }

  cols: any[];
  tables: Table[];
  newTable: Table;
  editTable: Table;
  tableExists: boolean = false;
  displayTableExistsDialogue: boolean = false;
  tableToRemove: Table;
  displayTableDeleteDialogue: boolean = false;
  deleteTableNumber: String;
  selectedTableQrCode: String;
  displayQrCodeDialogue: boolean = false;
  displayEditTableDialogue: boolean = false;
  waitersForDropDown: SelectItem[];
  waiters: Waiter[];
  restaurantId: number;
  qrcodeList: string[];

  ngOnInit() {
    this.newTable = new Table();
    this.editTable = new Table();
    this.restaurantId = this.appLoadService.getRestaurantId();
    this.initiateColumnHeaders();
    this.reloadData();
    this.fetchWaiters();

  }

  fetchWaiters() {
    this.waiterService.getWaitersForTables(this.restaurantId).subscribe(response => {
      let waiterArray: Waiter[];
      this.waitersForDropDown = [];
      this.waiters = [];
      waiterArray = response;
      this.waiters = response;
      waiterArray.forEach(el => {
        this.waitersForDropDown.push({
          label: el.waiterName +'-'+el.waiterCode , value: el.waiterCode
        });
      });

    });
  }

  initiateColumnHeaders() {
    this.cols = [{ field: 'tableNumber', header: 'Table Number' },
    { field: 'table_capacity', header: 'Capacity' },
    { field: 'activeFlagString', header: 'Available' },
    { field: 'selectedWaiter', header: 'Assigned Waiter' }];
  }

  addTable() {
    this.tableExists = this.tables.some(table => table.tableNumber.toUpperCase() === this.newTable.tableNumber.toUpperCase());
    if (!this.tableExists) {
      this.waiters.forEach(waiter => {
        if (this.newTable.selectedWaiter === waiter.waiterCode) {
          this.newTable.waiter = waiter;
        }
      });
      this.tableService.addTable(this.newTable, this.restaurantId)
        .subscribe(data => this.reloadData());
      this.newTable = new Table();
    } else {
      this.displayTableExistsDialogue = true;
    }

  }


  updateTable() {
    this.waiters.forEach(waiter => {
      if (this.editTable.selectedWaiter === waiter.waiterCode) {
        this.editTable.waiter = waiter;
      }
    });
    this.tableService.updateTable(this.editTable)
      .subscribe(data => this.reloadData());
    this.editTable = new Table();
    this.displayEditTableDialogue = false;
  }

  removeTableSelect(event) {
    this.tableToRemove = event;
    this.deleteTableNumber = this.tableToRemove.tableNumber;
    this.displayTableDeleteDialogue = true;
  }


  removeTableConfirm() {
    this.tableService.deleteTable(this.tableToRemove.id)
      .subscribe(data => this.reloadData());
    this.displayTableDeleteDialogue = false;
  }


  reloadData() {
    this.tableService.getTablesList(this.restaurantId)
      .subscribe(data => {
        this.tables = data;
       
      });
  }

  showQrCodeDialogue(event) {
    this.tableToRemove = event;
    //this.displayQrCodeDialogue = true;
    this.deleteTableNumber = this.tableToRemove.tableNumber;
    this.selectedTableQrCode = this.tableToRemove.qrCodeUrl;
    console.log(this.tableToRemove.qrCodeUrl);
    this.downloadPdf(this.restaurantId, this.deleteTableNumber);
  }


  editTableDialogue(event) {
    this.tableToRemove = event;
    this.displayEditTableDialogue = true;
    this.tableService.getTable(this.tableToRemove.id, this.restaurantId)
      .subscribe(data => {
        this.editTable = data;
      });

  }

  downloadPdf(restaurantId:number, tableNumber:any){
    // this.pdfDownloadService.downloadSinglePdf(2,1)
    // .subscribe(data =>{
    //   console.log(data);
    // });
    
      this.pdfDownloadService.saveFile(restaurantId, tableNumber);
    
  }

  downloadAllPdf(){
    this.pdfDownloadService.downloadAllPdfs(this.restaurantId);
  }



  

}
