import { Injectable,  Injector } from "@angular/core";
import { Observable } from "rxjs";
import { OktaAuthService } from '@okta/okta-angular';
import { HttpClient } from "@angular/common/http";
import { Order } from "../models/order.model";
import { map } from "rxjs/operators";




@Injectable({
    providedIn: 'root'
  })

export class OrdersService {

    private baseUrl = 'https://api.bludin.com/ordersApi';
   //private baseUrl = 'http://localhost:5000/ordersApi';
    constructor(private http:HttpClient) { }

   loadOrders(restaurantId:number):Observable<any>{    
       return this.http.get<Order[]>(`${this.baseUrl}/orders/${restaurantId}`)
       .pipe(map(data => data));       
   }

    
}