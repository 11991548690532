export default {
  oidc: {
 //clientId: '0oaidew1mognDHm7n0h7', //local 
  /// clientId: '0oakl7q9rm7eGNPLW0h7', //firebase

 clientId: '0oan5i6f96eA6pxGH0h7', //firebase uat

issuer: 'https://dev-903633.oktapreview.com/oauth2/default',
 

  //   redirectUri:'https://orderapp-merchant.firebaseapp.com/implicit/callback',
  redirectUri:'https://merchant.bludin.com/implicit/callback',
      
//redirectUri: 'http://localhost:8080/implicit/callback',
    scope: 'openid profile email',
  }, 
};