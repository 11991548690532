export class Restaurant {
    restaurantId:number;
    restaurantName: string;
    active: boolean;
    deletedFlag: boolean;
    restaurantAddress: string;
    restaurantEmail: string;
    restaurantPhone: string;

    constructor(){
      this.restaurantName ='';
      this.restaurantAddress = '';
      this.restaurantEmail ='';
      this.restaurantPhone ='';
      this.active = true;  
    }
}
