import { Category } from "./category.model";
import { Restaurant } from "./restaurant.model";
export class MenuItem {
    id:number;
    name: String;
    price: number;
    isVeg: String;
    description: string;
    active: boolean;
    category: Category;
    selectedCategory: number;   
    activeFlagString: string;
    isVegString:string;
    restaurant:Restaurant;
    isRecommended:boolean;
    isRecommendedString:string;
    image: FormData;
    imageUrl:string;
    constructor(){
      this.name ='';
      this.isVeg = '';
      this.description ='';
      this.active = true;
      this.isRecommended = false;
      this.isRecommendedString = '';
      this.imageUrl = '';
      this.category = new Category();      
    }
}
