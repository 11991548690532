import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { Restaurant } from '../merchant/models/restaurant.model';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;

  submitted = false;
  registered = false;
  alreadyExist = false;
  formDisable = false;
  timer;

  model = new Restaurant();
  

  constructor(private userService:UserService, private router: Router)
 
  {
    this.model.restaurantId = 0;
    this.model.restaurantName = "";
    this.model.restaurantAddress = "";
    this.model.restaurantEmail = "";
    this.model.restaurantPhone = "";
    this.model.active = false;
    this.model.deletedFlag = false;



  }

  ngOnInit() {

  }


  onSubmit() {
    this.formDisable = true;
   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model));
    // this.userService.registerRestaurant(this.model).subscribe(data=> {
    //         this.registered = true;
    // });
     this.userService.checkRestaurantExists(this.model.restaurantEmail).subscribe(data => {
       console.log(data);
       if(data != null && data.restaurantEmail == this.model.restaurantEmail){
       // clearTimeout(this.timer);
       this.alreadyExist = true;
       this.formDisable = false;
        this.timer = setTimeout(() => {
          this.alreadyExist = false;
        //  this.formDisable = true;
        }, 5000);
         
         
      }else {
         this.userService.registerRestaurant(this.model).subscribe(data=> {
           this.registered = true;
           this.timer = setTimeout(() => {
            this.router.navigateByUrl('');
          }, 5000);
         });
      }
      
     });    
    //niranjanreddy9001@gmail.com
  }
  log(x) {
    console.log(x);
  }

}
