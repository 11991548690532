import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {MyAppLoadService} from './myapp-load.service';
import { OktaAuthService } from '@okta/okta-angular';
import { Observable, from } from 'rxjs';

@Injectable()
export class RestaurantDetailsGaurd implements CanActivate {
    isAuthenticated: boolean;
    
    constructor(private router: Router,public oktaAuth: OktaAuthService,private appLoadService: MyAppLoadService) {   
    }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return from(this.extractEmailAndGetRestaurantDetails());       
    }



    private async extractEmailAndGetRestaurantDetails(): Promise<boolean> {
        let userName:string;
     this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      const userClaims = await this.oktaAuth.getUser();     
      userName = userClaims.email;     
      await this.appLoadService.getRestaurantDetails(userName);
      return true;
    }
        
    }


   }