import { Table } from '../models/table.model';
import { Restaurant } from "./restaurant.model";
export class Waiter {
    id:number;  
    waiterCode: string;
    waiterName: string;
    activeFlag: boolean;
    activeFlagString: string;
    selectedTables: string[];    
    tables: Table[];
    restaurant:Restaurant;
    constructor(){
      this.waiterCode ='';
      this.waiterName= '';      
      this.activeFlag = true;  
      this.selectedTables =[];
      this.tables = [];    
    }
}
