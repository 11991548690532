import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ManageMenuItemsComponent} from './merchant/manage-restaurant/manage-menu-items/manage-menu-items.component';
import {ManageMenuCategoriesComponent} from './merchant/manage-restaurant/manage-menu-categories/manage-menu-categories.component';
import { ManageTablesComponent } from './merchant/manage-restaurant/manage-tables/manage-tables.component';
import { ManageWaiterComponent } from './merchant/manage-restaurant/manage-waiter/manage-waiter.component';
import { OrdersComponent } from './merchant/manage-restaurant/orders/orders.component';
import { OktaAuthModule,OktaCallbackComponent,  OktaAuthGuard, OktaLoginRedirectComponent} from '@okta/okta-angular';
import config from './.okta.config';
import {RestaurantDetailsGaurd} from './restaurant-details.gaurd';
import { HomeComponent } from './home/home.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { RegisterComponent } from './register/register.component';

const appRoutes: Routes  = [

  {
    path: '', component: HomeComponent,
  },
  {
    path: 'logOut', component: HomeComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'userDetails', canActivate: [OktaAuthGuard],
    children: [
      {
        path: '', canActivate: [RestaurantDetailsGaurd], component: UserDetailsComponent
      }
    ]
  }, 

  {
    path:'menu-categories', canActivate: [OktaAuthGuard],
    children: [
      {
        path: '', canActivate: [RestaurantDetailsGaurd], component: ManageMenuCategoriesComponent
      }
    ]    
  },
  {
    path:'menu-items', canActivate: [OktaAuthGuard],
    children: [
      {
        path: '', canActivate: [RestaurantDetailsGaurd], component: ManageMenuItemsComponent
      }
    ]    
  },

  {
    path:'tables', canActivate: [OktaAuthGuard],
    children: [
      {
        path: '', canActivate: [RestaurantDetailsGaurd], component: ManageTablesComponent
      }
    ]      
  },
  {
    path:'waiters', canActivate: [OktaAuthGuard],
  
    children: [
    {
      path: '', canActivate: [RestaurantDetailsGaurd], component: ManageWaiterComponent
    }
  ]      
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'orders', canActivate: [OktaAuthGuard],
    children: [
      {
        path: '', canActivate: [RestaurantDetailsGaurd], component: OrdersComponent
      }
    ]    
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes),
    OktaAuthModule.initAuth(config.oidc)],
  exports: [RouterModule]
 
})
export class AppRoutingModule{}
export const routingComponents = [HomeComponent, RegisterComponent, UserDetailsComponent, ManageMenuItemsComponent, ManageMenuCategoriesComponent,ManageTablesComponent,
  ManageWaiterComponent,OrdersComponent];
