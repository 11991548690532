import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { OktaAuthService } from '@okta/okta-angular';
import {MyAppLoadService} from './myapp-load.service';
import * as firebase from  'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'order-app';
  public spinkit = Spinkit;
  isAuthenticated: boolean;

  constructor(public oktaAuth: OktaAuthService, private appLoadService: MyAppLoadService, private router: Router) {
    
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(      
      (isAuthenticated)  => {
        this.isAuthenticated = isAuthenticated                    
      }
    );

    const firebaseConfig = {     
      apiKey: "AIzaSyBVOd5BxNdjtjnjadFO16cnbyEPrC7iekM",
  authDomain: "customerorderappandroid.firebaseapp.com",
  databaseURL: "https://customerorderappandroid.firebaseio.com",
  projectId: "customerorderappandroid",
  storageBucket: "customerorderappandroid.appspot.com",
  messagingSenderId: "766865156900"
    };    
    firebase.initializeApp(firebaseConfig);


  }

  async ngOnInit() {

    // Get the authentication state for immediate use
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();

  }

  login() {
    this.oktaAuth.loginRedirect('/menu-categories');  
  }

  logout() {
    //this.router.navigateByUrl('');
    this.oktaAuth.logout('');
    
    
  }
}
