import { Component, OnInit } from '@angular/core';
import { MyAppLoadService } from 'src/app/myapp-load.service';
import { Order } from '../../models/order.model';
import { OrdersService } from '../../services/orders.services';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ExcelService } from '../../services/excel.service';
import { OrderExport } from '../../models/orderExport.model';
import { orderStatus } from '../../models/orderStatus.model';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
   columnDefs;
  private gridApi;
  private gridColumnApi;
  private searchValue;
  private restaurantId;
   orders: Order[] = [];    
  private ordersForExport: OrderExport[] = [];  



  constructor(private appLoadService: MyAppLoadService, private orderService:OrdersService, private excelService:ExcelService) { }

  ngOnInit() {
    this.restaurantId=this.appLoadService.getRestaurantId();
    this.initiateColumnHeaders();
  
    this.loadOrders();
  
  }
  initiateColumnHeaders() {
    this.columnDefs = [
      {headerName: 'Order Id', field: 'orderId',width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Waiter Name', field: 'waiter.waiterName',width: 150, sortable: true, filter: true,resizable: true},
      {headerName: 'Table Id', field: 'table.tableNumber', width: 90,sortable: true, filter: true,resizable: true},
      {headerName: 'Status', field: 'orderStatus.statusName',width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Customer Mobile', field: 'customerMobileNumber',width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Cart Total Amount', field: 'totalCartAmount',width: 150, sortable: true, filter: "number",resizable: false},
      {headerName: 'GST Amount', field: 'gstAmount',width: 100, sortable: true, filter: "number",resizable: false},
      {headerName: 'Total Bill', 
      valueGetter: function aPlusBValueGetter(params) {
        return (params.data.totalCartAmount + params.data.gstAmount) as number | CurrencyPipe;
      },width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Order Date', 
      field: 'orderDate',
      valueFormatter: function (params) {
        return moment(params.value).format('MM/DD/YYYY HH:mm:ss');
      },
      filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format('MM/DD/YYYY');

            console.log(dateAsString);
            if (dateAsString == null) return 0;

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            var dateParts = dateAsString.split("/");
            var day = Number(dateParts[1]);
            var month = Number(dateParts[0]);
            var year = Number(dateParts[2]);
            console.log("Day"+day);
            console.log("Month"+month);
            console.log("year"+year);
            var cellDate = new Date(year, month-1, day);
            console.log("celldate: "+cellDate);
            console.log("filterLocalDateAtMidnight: "+filterLocalDateAtMidnight);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              console.log("return"+-1);
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              console.log("return"+1);
                return 1;
            } else {
              console.log("return"+0);
                return 0;
            }
        }
        },             
      width: 150, sortable: true, resizable: true},
      {headerName: 'Payment Mode', field: 'paymentMode',width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Payment Status', field: 'paymentStatus.statusName',width: 100, sortable: true, filter: true,resizable: true},
      {headerName: 'Customer Comments', field: 'customerComments', width: 100,sortable: true, filter: false,resizable: true},
      {headerName: 'Customer Comments', field: 'customerComments', width: 100,sortable: true, filter: false,resizable: true}
      

  ];
  }


   exportAsXLSX():void {    
    this.excelService.exportAsExcelFile(this.ordersForExport, this.orders[0].restaurant.restaurantName);
  }


  onGridReady(params){
    //console.log(params);
    //this.gridApi = params.api;
    //console.log(this.gridApi);
    // {
    //   headerName: "A + B",
    //   colId: "a&b",
    //   valueFormatter: currencyFormatter,
    //   cellClass: "number-cell",
    //   valueGetter: function aPlusBValueGetter(params) {
    //     return params.data.a + params.data.b;
    //   }
    // },
    //valueFormatter: currencyFormatter,
  }

  loadOrders() {
    
    this.orderService.loadOrders( this.restaurantId).
      subscribe(data => {
        this.orders = data;       
        this.orders.forEach(order => {
          let orderExport:OrderExport = new OrderExport();  
          orderExport.orderId = order.orderId;     
          orderExport.orderDate = moment(order.orderDate).format('MM/DD/YYYY');
          orderExport.orderTime = moment(order.orderDate).format('HH:mm:ss');
          orderExport.tableNumber = order.tableNumber;
          orderExport.waiterName = order.waiter.waiterName;    
          orderExport.customerMobileNumber = order.customerMobileNumber;        
          orderExport.gstAmount = order.gstAmount;
          orderExport.totalCartAmount = order.totalCartAmount;
          orderExport.totalBill = order.gstAmount + order.totalCartAmount;         
          orderExport.orderStatus = order.orderStatus.statusName;                            
          orderExport.paymentStatus = order.paymentStatus.statusName;
          orderExport.paymentMode = order.paymentMode;  
          orderExport.customerComments = order.customerComments;
          orderExport.customerFeedback = order.customerFeedback;
          console.log(order.paymentMode);
          this.ordersForExport.push(orderExport);
        }) 
      });

      
  }
  // currencyFormatter (params) {
   
  //     return "&#x20B9" + this.formatNumber(params.value);
  
  // }
  // formatNumber(number) {
  //   return Math.floor(number)
  //     .toString()
  //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // }



  
}
