
export class OrderExport {
    orderId:number;      
    orderStatus: String;    
    paymentStatus:String;
    paymentMode:String;
    tableNumber: string;
    totalCartAmount: number;
    gstAmount: number;
    customerComments: string;
    customerFeedback:string;
    customerMobileNumber: string;    
    waiterName:string;
    orderTime: string;
    orderDate: string;
    totalBill: number;

    constructor(){
    }
    
}
