import { NgModule } from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import{MultiSelectModule} from 'primeng/multiselect';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FileUploadModule} from 'primeng/fileupload';


@NgModule({
  imports :[ MenuModule, InputTextModule, TableModule,
     DialogModule, ButtonModule, ToggleButtonModule,
     DropdownModule,InputTextareaModule],
  exports :[ MenuModule, InputTextModule, TableModule,
    DialogModule,ButtonModule,ToggleButtonModule,
    DropdownModule, InputTextareaModule, MultiSelectModule, InputSwitchModule, FileUploadModule]
})
export class AppPrimeNGModule{}
