import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Table } from '../models/table.model';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private baseUrl = 'https://api.bludin.com/tableApi';
//  private baseUrl = 'http://localhost:8000/tableApi';

  constructor(private http: HttpClient) { }
  getTablesList(restaurantId:number){
    return this.http.get<Table[]>(`${this.baseUrl}/tables/${restaurantId}`)
      .pipe(map(data => data));
    }

    getTablesForWaiters(restaurantId:number){
      return this.http.get<Table[]>(`${this.baseUrl}/tablesForWaiters/${restaurantId}`)
        .pipe(map(data => data));
      }
  

  addTable(table: Object, restaurantId:number): Observable<any> {
    return this.http.post(`${this.baseUrl}/tables/${restaurantId}` , table);
  }

  updateTable(table: Object): Observable<any> {
    return this.http.put(`${this.baseUrl}/tables` , table);
  }

  deleteTable(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/tables/${id}`, { responseType: 'text' });
  }

  getTable(id: number, restaurantId:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/tables/${id}/${restaurantId}`);
  }


  
}
