import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Restaurant } from '../merchant/models/restaurant.model';
import { map } from 'rxjs/operators';


//import { User } from '../models';

@Injectable({
    providedIn: 'root'
}
)
export class UserService {

//     http://api.orderapp.xyz/restaurantApi/restaurant/{email}

// First Check if restaurant Already Exists, if Exists Error Out, iF nt exists then send the request for Saving

// http://api.orderapp.xyz/restaurantApi/restaurant/

    private baseUrl = 'https://api.bludin.com/restaurantApi';
    //private baseUrl = 'http://localhost:8000/restaurantApi/';

    constructor(private http: HttpClient) { }

    checkRestaurantExists(email:string):Observable<any>{
        return this.http.get<Restaurant[]>(`${this.baseUrl}/restaurant/${email}`)
      .pipe(map(data => data));
    }


    registerRestaurant(restaurant: Restaurant):Observable<any> {
        return this.http.post(`${this.baseUrl}/restaurant`, restaurant);
    }
    
    // getAll() {
    //     return this.http.get<User[]>(`/users`);
    // }

    // getById(id: number) {
    //     return this.http.get(`/users/` + id);
    // }

    // register(user: User) {
    //     return this.http.post(`/users/register`, user);
    // }

    // update(user: User) {
    //     return this.http.put(`/users/` + user.id, user);
    // }

    // delete(id: number) {
    //     return this.http.delete(`/users/` + id);
    // }
}