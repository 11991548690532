import { MenuItem } from "./menuItem.model";
import { Restaurant } from "./restaurant.model";

export class Category {
    categoryId: number;
    categoryName: string;
    description:string;
    active:boolean;
    activeFlagString: string;
    menuItems:MenuItem[];
    image:File;
    restaurant:Restaurant;
    

    constructor() {
      this.categoryId =0;
      this.categoryName= '';
      this.description = '';
      this.active=true;
      this.menuItems=[];
    }
}
