import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../models/menuItem.model';
import { SelectItem } from 'primeng/api';
import { Category } from '../../models/category.model';
import { CategoryService } from '../../services/category.service';
import { MenuItemService } from '../../services/menuItem.service';
import {MyAppLoadService} from '../../../myapp-load.service';
import { UploadFileService } from '../../services/upload-file.service';


@Component({
  selector: 'app-manage-menu-items',
  templateUrl: './manage-menu-items.component.html',
  styleUrls: ['./manage-menu-items.component.css']

})
export class ManageMenuItemsComponent implements OnInit {

  constructor(private categoryService: CategoryService,
    private menuItemService: MenuItemService, private appLoadService: MyAppLoadService, private uploadService: UploadFileService) { }

  cols: any[];
  menuItems: MenuItem[] = [];
  newMenu: MenuItem;
  editMenu:MenuItem;
  deleteMenuIteam: MenuItem;
  selectedMenuItemForImage: MenuItem;
  selectedImageUrl:string;
  isMenuIteamEditable: boolean = false;
  categories: Category[];
  selectedCategory: number;
  displayDeleteDialogue: boolean = false;
  deletMenuItemId: number;
  categoriesForDropDown:SelectItem[];
  displayMenuItemExistsDialogue:boolean = false;
  displayImageDialogue:boolean = false;
  restaurantId:number;
  selectedFiles: FileList;

  ngOnInit() {
    this.restaurantId=this.appLoadService.getRestaurantId();
    this.initiateColumnHeaders();
    this.fetchCategories();
    this.loadMenuItemsData();
  }

  initiateColumnHeaders() {
    this.cols = [{ field: 'name', header: 'Name' },
    { field: 'price', header: 'Price' },
    { field: 'isVegString', header: 'Vegiterian' },
    { field: 'category', subfield: 'categoryName', header: 'Category' },
    { field: 'activeFlagString', header: 'Available' },
    { field: 'isRecommended', header: 'Recommended'},    
    { field: 'description', header: 'Description' }
     ];
  }

  loadMenuItemsData() {
    this.newMenu = new MenuItem();
    this.menuItemService.loadMenuItems( this.restaurantId).
      subscribe(data => {
        this.menuItems = data;
      })
  }

  addMenuItem() {
    var menuItemExists = this.menuItems.some(menuItem => menuItem.name.toUpperCase() === this.newMenu.name.toUpperCase());
    if(!menuItemExists) {      
      this.findCategoryObejct();
      let file  =  null;
      if(this.selectedFiles != undefined) {
        file  =  this.selectedFiles.item(0);
        this.selectedFiles = null;
      }     
      if(this.newMenu.isRecommended && file != null) {
        console.log("======"+this.newMenu.name);
        this.uploadAndTrackURL(file);
      } else {
       //this.newMenu.imageUrl = null;  
        this.menuItemService.addMenuItem(this.newMenu, this.restaurantId).
        subscribe(data => this.loadMenuItemsData());
      }
       
    this.newMenu = new MenuItem();
    } else {
      this.displayMenuItemExistsDialogue = true;
    }
    
  }

  private findCategoryObejct() {
    this.categories.forEach(category => {
      if (this.newMenu.selectedCategory === category.categoryId) {
        this.newMenu.category = category;
      }
    });
  }

 uploadAndTrackURL(file:any){  
      this.uploadService.uploadfile(file, this.newMenu).then((data) => {
        this.newMenu = <MenuItem>data;
        console.log("After S3" + data)
        console.log("======"+this.newMenu.name);
        this.menuItemService.addMenuItem(this.newMenu, this.restaurantId).
        subscribe(data => this.loadMenuItemsData());
      });
      
  }

  updateMenuItem() {    
    this.findCategoryObejct();
    let file  =  null;
    if(this.selectedFiles != undefined) {
      file  =  this.selectedFiles.item(0);
      this.selectedFiles = null;
    }  
    if(this.newMenu.isRecommended && file != null) {
      console.log("======"+this.newMenu.name);
      this.uploadAndTrackURL(file);
    } else { 
      if(!this.newMenu.isRecommended) {
       this.newMenu.imageUrl = null;  
      }
     
      this.menuItemService.addMenuItem(this.newMenu, this.restaurantId).
      subscribe(data => this.loadMenuItemsData());
    }
    this.newMenu = new MenuItem();
    this.isMenuIteamEditable = false; 
    
  }

  changedropdownValues(value: any) {
    //this.newMenu.category.categoryId = value;
  }

  editMenuItem(event) {   
    this.isMenuIteamEditable = true;    
    this.editMenu = event;
    let categoryPresent = false;
    this.categoriesForDropDown.forEach(category => {      
      if(category.value === this.editMenu.selectedCategory){
        categoryPresent = true;
      }
    });
    if(!categoryPresent) {
     this.categoriesForDropDown.push({
       label: this.editMenu.category.categoryName, value: this.editMenu.category.categoryId
     }); 
    }   
    this.menuItemService.getMenuItemByID(this.editMenu.id, this.restaurantId).subscribe(data =>{
      this.newMenu = data;    
    })

  }

  cancelEditMenuItem() {
    this.isMenuIteamEditable = false;
    this.loadMenuItemsData();
    this.fetchCategories();
  }

  removeMenuItem(event) {
    this.deleteMenuIteam = event;
    this.deletMenuItemId = this.deleteMenuIteam.id;
    this.displayDeleteDialogue = true;
  }

  fetchCategories() {
    this.categoryService.getCategoriesListForMenu( this.restaurantId).
      subscribe(data => {
        this.categories = data;
        this.categoriesForDropDown = [];
        this.categories.forEach(el => {
          this.categoriesForDropDown.push({
             label: el.categoryName, value: el.categoryId
          });
       });  
      });
  }

  removeMenuItemConfirm() {
    this.menuItemService.deleteMenuIteam(this.deletMenuItemId)
      .subscribe(data => this.loadMenuItemsData());
    this.displayDeleteDialogue = false;
  }

  

  // upload() {
  //   const file = this.selectedFiles.item(0);
  //   this.uploadService.uploadfile(file);
  // }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }


  showImageDialogue(event) {
     this.selectedMenuItemForImage = event;
    // this.displayImageDialogue = true;
    // this.selectedImageUrl = this.selectedMenuItemForImage.imageUrl;
    let a: any = document.createElement('a');
  a.href = this.selectedMenuItemForImage.imageUrl;
  a.download = 'image';
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
    
  }




  

}
