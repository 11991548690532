import { HttpClient } from "../../../../node_modules/@angular/common/http";
import { Table } from "../../../../node_modules/primeng/table";
import { map } from "../../../../node_modules/rxjs/operators";
import { Injectable } from "../../../../node_modules/@angular/core";
import { Observable } from "../../../../node_modules/rxjs";
import { Category } from "../models/category.model";


@Injectable({
    providedIn: 'root'
  })

export class CategoryService {

    private baseUrl = 'https://api.bludin.com/categoriesApi';
    //private baseUrl = 'http://localhost:8000/categoriesApi';

    constructor(private http:HttpClient) { }

    getCategoriesList(restaurantId:number):Observable<any>{
        return this.http.get<Category[]>(`${this.baseUrl}/categories/${restaurantId}`)
      .pipe(map(data => data));
    }

    getCategoriesListForMenu(restaurantId:number):Observable<any>{
      return this.http.get<Category[]>(`${this.baseUrl}/categoiesForMenu/${restaurantId}`)
    .pipe(map(data => data));
  }
    
    getCategoryByID(category_Id:number, restaurantId:number): Observable<any>{
      return this.http.get(`${this.baseUrl}/categories/${category_Id}/${restaurantId}`);
      
    }

    addCategories(category: Object, restaurantId:number):Observable<any> {
      return this.http.post(`${this.baseUrl}/categories/${restaurantId}`, category);
    }
    
    removeCategory(category_Id:number) {
      return this.http.delete(`${this.baseUrl}/categories/${category_Id}`, {responseType: 'text'})
    }
}