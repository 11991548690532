import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Waiter } from '../models/waiter.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WaiterService {

private baseUrl = 'https://api.bludin.com/waiterApi';
//  private baseUrl = 'http://localhost:8000/waiterApi';

  constructor(private http: HttpClient) { }
  getWaitersList(restaurantId:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/waiters/${restaurantId}`);
  }

  addWaiter(waiter: Object, restaurantId:number): Observable<any> {
    return this.http.post(`${this.baseUrl}/waiters/${restaurantId}` , waiter);
  }

  updateWaiter(waiter: Object): Observable<any> {
    return this.http.put(`${this.baseUrl}/waiters` , waiter);
  }

  deleteWaiter(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/waiters/${id}`, { responseType: 'text' });
  }

  getWaiter(id: number, restaurantId:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/waiters/${id}/${restaurantId}`);
  }

  getWaitersForTables(restaurantId:number) : Observable<any>{
    return this.http.get(`${this.baseUrl}/waitersForTables/${restaurantId}`);    
    }


  
}
