import { HttpClient, HttpHeaders } from "../../../../node_modules/@angular/common/http";
import { Injectable,  Injector } from "../../../../node_modules/@angular/core";
import { Observable } from "../../../../node_modules/rxjs";
import { OktaAuthService } from '@okta/okta-angular';

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}

@Injectable({
    providedIn: 'root'
  })

export class MenuItemService {

  //   private baseUrl = 'http://localhost:8000/menuItemApi/menuItems';
    private baseUrl = 'https://api.bludin.com/menuItemApi/menuItems';

    constructor(private http:HttpClient) { }

   loadMenuItems(restaurantId:number):Observable<any>{    
       return this.http.get(`${this.baseUrl}/${restaurantId}`);       
   }

    addMenuItem(menuItemDTO: Object, restaurantId:number):Observable<any> {
      return this.http.post(`${this.baseUrl}/${restaurantId}`, menuItemDTO, {
        reportProgress: true,
        responseType: 'text'
      });
    }
   

    getMenuItemByID(menuItem_Id:number, restaurantId:number): Observable<any>{
        return this.http.get(`${this.baseUrl}/${menuItem_Id}/${restaurantId}`);
        
    }

    deleteMenuIteam(menuIteamId:number):Observable<any> {
        return this.http.delete(`${this.baseUrl}/${menuIteamId}`, {responseType: 'text'})
    }
    
}