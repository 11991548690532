import { Component, OnInit } from '@angular/core';
import {  WaiterService} from '../../services/waiter.service';
import {  TableService} from '../../services/table.service';
import { Waiter } from '../../models/waiter.model';
import { Table } from '../../models/table.model';
import {SelectItem} from 'primeng/api';
import {MyAppLoadService} from '../../../myapp-load.service';

@Component({
  selector: 'app-manage-waiter',
  templateUrl: './manage-waiter.component.html',
  styleUrls: ['./manage-waiter.component.css']
})
export class ManageWaiterComponent implements OnInit {

  constructor(private waiterService: WaiterService, private tableService: TableService,private appLoadService: MyAppLoadService) {

    
  }

 cols: any[];
 waiters: Waiter[];
 tables:SelectItem[];
 tableObjects : Table[];
 newWaiter: Waiter; 
 editWaiter: Waiter;   
 waiterExists:boolean = false;
 displayWaiterExistsDialogue: boolean = false;
 waiterToRemove: Waiter; 
 displayWaiterDeleteDialogue: boolean = false;
 deleteWaiterId:String;
 selectedTablesForRemovedWaiter:string[];
 displayEditWaiterDialogue:boolean = false;  
 displayWaiterInactiveDialogue:boolean = false;
 restaurantId:number;

 ngOnInit() {
   this.newWaiter = new Waiter();
   this.editWaiter = new Waiter(); 
   this.waiters = [];     
   this.restaurantId=this.appLoadService.getRestaurantId();
   this.initiateColumnHeaders();
   this.reloadData();   
   this.getUnAssignedTables();
  
}

getUnAssignedTables() {  
  this.tableService.getTablesForWaiters(this.restaurantId).subscribe(response=> {
    let tableArray: Table[];
    this.tables = [];
    tableArray = response;
    this.tableObjects = response;
    tableArray.forEach(el => {
      this.tables.push({
         label: el.tableNumber, value: el.tableNumber
      });
   });  
  
});
}

 initiateColumnHeaders(){
   this.cols = [{ field: 'waiterCode', header: 'Waiter Code' },
                 {field: 'waiterName', header:'Waiter Name'},                 
                 {field: 'activeFlagString', header:'Available'},
                 {field: 'waiterMobileNumber', header:'Mobile'},
                 {field: 'selectedTables', header:'Tables'}];
 }

 addWaiter(){  
  this.waiterExists = this.waiters.some(waiter => waiter.waiterCode.toUpperCase() === this.newWaiter.waiterCode.toUpperCase());
   if(!this.waiterExists){
    this.newWaiter.tables = [];
    this.tableObjects.forEach(table => {
      this.newWaiter.selectedTables.forEach(selectedTable => {
        if (table.tableNumber === selectedTable) {
            this.newWaiter.tables.push(table);               
        }
      });
    });
    if(!this.newWaiter.activeFlag) {
      this.newWaiter.selectedTables = [];
      this.newWaiter.tables = [];
    }
    this.waiterService.addWaiter(this.newWaiter,this.restaurantId)
    .subscribe(data => {
      this.reloadData()
      this.getUnAssignedTables();   
    });    
     this.newWaiter = new Waiter();   
    
   } else {
     this.displayWaiterExistsDialogue = true;
   }

 }


  
 updateWaiter(){ 
   if(this.editWaiter.selectedTables.length > 0) {
    this.editWaiter.tables = [];
    this.tableObjects.forEach(table => {
      this.editWaiter.selectedTables.forEach(selectedTable => {
        if (table.tableNumber === selectedTable) {
            this.editWaiter.tables.push(table);               
        }
      });
    });
   }  else {
     this.editWaiter.tables =[];
   }
  
   if(!this.editWaiter.activeFlag) {
      this.editWaiter.selectedTables = [];
      this.editWaiter.tables = [];
   }
   this.waiterService.updateWaiter(this.editWaiter)
    .subscribe(data => {
      this.reloadData();
      this.getUnAssignedTables();
    });    
   // this.reloadData()
     this.editWaiter = new Waiter();   
     this.displayEditWaiterDialogue = false; 
     
  }

 removeWaiterSelect(event){
   this.waiterToRemove = event;  
   this.deleteWaiterId = this.waiterToRemove.waiterCode;  
   this.selectedTablesForRemovedWaiter = this.waiterToRemove.selectedTables;
   this.displayWaiterDeleteDialogue = true;
 }


 removeWaiterConfirm(){    
  this.waiterService.deleteWaiter(this.waiterToRemove.id)
  .subscribe(data => {
    this.reloadData();
    this.getUnAssignedTables();
  });  
   this.displayWaiterDeleteDialogue = false;
 }


 reloadData(){
   this.waiterService.getWaitersList(this.restaurantId)
   .subscribe(data =>{
     this.waiters = data;
   });
 }

 editWaiterDialogue(event){ 
   this.waiterToRemove = event;      
   this.displayEditWaiterDialogue = true;
   
  this.waiterService.getWaiter( this.waiterToRemove.id, this.restaurantId)
   .subscribe(data =>{
    this.editWaiter = data;
    this.editWaiter.selectedTables.forEach(tableName=>{    
     let tableNamePresent = false; 
      this.tables.forEach(element => {
        if(element.value === tableName) {
          tableNamePresent = true;
        }
      });
      if(!tableNamePresent) {
        this.tables.push({
          label: tableName, value: tableName
        });  
      }
           
    });

    this.editWaiter.tables.forEach(table =>{
      let tablePresent = false; 
      this.tableObjects.forEach(element => {
        if(element.tableNumber === table.tableNumber) {
          tablePresent = true;
        }
      });
      if(!tablePresent) {
        this.tableObjects.push(table);
      }
     
    });

    
   });  

 }

 showPopOpForInactiveWaiter(){
  if(this.editWaiter.selectedTables.length > 0 && !this.editWaiter.activeFlag) {
    this.displayWaiterInactiveDialogue = true;
  } 
}

cancelWaiterEdit(){
  this.displayEditWaiterDialogue = false;
  this.getUnAssignedTables();
}

inActiveWaiterConfirm(){
 this.waiterService.updateWaiter(this.editWaiter)
      .subscribe(data => this.reloadData());   
  this.displayEditWaiterDialogue = false;
  this.displayWaiterInactiveDialogue = false;
}



}
