import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents} from './app-routing.module';
import { AppPrimeNGModule} from './app-primeng-module';
import { MerchantMenuBarComponent } from './merchant/manage-restaurant/merchant-menu-bar/merchant-menu-bar.component';
import { HttpServices } from './httpService';
import { HttpClient, HttpHandler, HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {DialogModule} from 'primeng/dialog';
import { QRCodeModule } from 'angularx-qrcode';
import {ButtonModule} from 'primeng/button';
import { NgHttpLoaderModule } from 'ng-http-loader';



import { MyAppLoadService } from './myapp-load.service';
import { TokenInterceptorService } from './token-interceptor.service';
import { RestaurantDetailsGaurd } from './restaurant-details.gaurd';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';
import { ExcelService } from './merchant/services/excel.service';




@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    MerchantMenuBarComponent
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppPrimeNGModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    QRCodeModule,
    ButtonModule,
    DialogModule,
    NgHttpLoaderModule.forRoot(),
    AgGridModule.withComponents([]) 
  ],
  providers: [HttpServices, HttpClient, MyAppLoadService,RestaurantDetailsGaurd, ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
