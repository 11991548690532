import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class HttpServices {
    constructor(private httpClient:HttpClient) { }

  post(url, data) {
    return this.httpClient.post(url, data, 
        {observe: 'body', params: null}).pipe(map((response) => {
        return response;
    }));
  }
  put(url, data, params) {
    return this.httpClient.put(url, data, params).pipe(map((response) => {
        return response;
    }));
  }
  delete(url) {
    return this.httpClient.delete(url).pipe(map((response) => {
        return response;
    }));
  }
  get(url) {
    return this.httpClient.get(url).pipe(map((response) => {
        return response;
    }));
  }
  getWithParams(url, params){
    return this.httpClient.get(url, {params: params}).pipe(map((response) => {
        return response;
    }));
  }
  handleError(){

  }
  
}
