import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs';
import { MenuItem } from '../models/menuItem.model';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  FOLDER = 'jsa-s3/';
  public fileLocation:any;
  isUploaded:boolean;  
  constructor() { }

   uploadfile(file, menu){

    return new Promise ((resolve, reject) => {

      const bucket = new S3(
        {
          accessKeyId: 'AKIA6QY5X7J76EE2WVGJ',
          secretAccessKey: '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',
          region: 'us-east-1'      
        }
      );
  
      const params = {
        Bucket: 'bludinapp',
        Key:  file.name,
        Body: file,
        ACL: 'public-read'
      };
  
       bucket.upload(params, function (err, data) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }
  
        console.log('Successfully uploaded file.', data);
  
        console.log('File Location is ', data.Location);
         this.fileLocation = data.Location;  
         console.log("Inside S3"+menu.name)
         menu.imageUrl = data.Location;       
         resolve(menu);
        return true;
      });

      


    })
    

  }

}
