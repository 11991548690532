import { Component, OnInit } from '@angular/core';
import { Category } from '../../models/category.model';
import {HttpServices} from '../../../httpService';
import { CategoryService } from '../../services/category.service';
import {SelectItem} from 'primeng/api';
import {FileUpload} from  'primeng/fileupload';
import {MyAppLoadService} from '../../../myapp-load.service';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-manage-menu-categories',
  templateUrl: './manage-menu-categories.component.html',
  styleUrls: ['./manage-menu-categories.component.css'],
  
})
export class ManageMenuCategoriesComponent implements OnInit {

  constructor(private categoryService:CategoryService, private appLoadService: MyAppLoadService, public oktaAuth: OktaAuthService) { }

  cols: any[];
  categories: Category[];
  category: Category;
  editCategoryObj: Category;
  deleteCategoryObj: Category;
  categoryName:String;
  isEditable: boolean = false;
  displayMenuItemDeleteDialogue:boolean = false;
  displayCategoryExistsDialogue:boolean = false;
  restaurantId:number;
  

  ngOnInit() {   
    this.restaurantId=this.appLoadService.getRestaurantId();
    this.initiateColumnHeaders();
    this.loadData();
  }

  initiateColumnHeaders(){
    this.cols = [{ field: 'categoryName', header: 'Name' },
                 { field: 'activeFlagString', header: 'Active' },
                 ];
  }

  onImageUpload(event) {
    let image = event;
    this.category.image = image;   
  }

  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
  }

  loadData(){
    this.category = new Category();   
    this.isEditable = false;
    this.categoryService.getCategoriesList(this.restaurantId)
    .subscribe(data =>{
      this.categories = data;
    });
  }

  addCategory(){
    var categoryExists = this.categories.some(category => category.categoryName.toUpperCase() === this.category.categoryName.toUpperCase());
    if(!categoryExists) {
      this.categoryService.addCategories(this.category, this.restaurantId)
      .subscribe(data => this.loadData());     
      this.category = new Category();  
    } else {
        this.displayCategoryExistsDialogue = true;
    }   
  }

  updateCategory() {
    this.categoryService.addCategories(this.category, this.restaurantId)
      .subscribe(data => this.loadData());     
      this.category = new Category(); 
      this.isEditable = false; 
  }

  cancelEditCategory() {
    this.loadData();  
  }

  editCategory(event) {
    this.editCategoryObj = event;   
    this.categoryService.getCategoryByID(this.editCategoryObj.categoryId, this.restaurantId).subscribe(data =>{
      this.category = data;      
    })
    this.isEditable = true;  
  }

  removeCategory(event){
    this.deleteCategoryObj = event;  
    this.categoryName = this.deleteCategoryObj.categoryName;
    this.displayMenuItemDeleteDialogue = true;
  }

  removeCategoryConfirm() {
    this.categoryService.removeCategory(this.deleteCategoryObj.categoryId)
      .subscribe(data => this.loadData());
    this.displayMenuItemDeleteDialogue = false;
  }
}
