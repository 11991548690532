//import injectable from core.
import { Injectable } from '@angular/core';
import { map, filter, switchMap } from 'rxjs/operators';
// below v4 we need to import 
//import { Http, Response, Headers, RequestOptions } from '@angular/http';
//Angular 4+ we need to import from common.
import { HttpClient } from '@angular/common/http';
import { Restaurant } from './merchant/models/restaurant.model';


import 'rxjs';
import { Observable } from 'rxjs';


@Injectable()
export class MyAppLoadService{


  private baseUrl = 'https://api.bludin.com/restaurantApi/restaurant';
  //private baseUrl = 'http://localhost:8000/restaurantApi/restaurant';
  constructor(private httpClient: HttpClient) {
      this.restaurant = new Restaurant();
   
   }

  
  restaurant: Restaurant;

    public getRestaurantId(): number {
        return this.restaurant.restaurantId;
    }

  getRestaurantDetails(restaurantEmail: string) {
    let promise = new Promise((resolve, reject) => {
      let apiURL = `${this.baseUrl}/${restaurantEmail}`;
      this.httpClient.get<Restaurant>(apiURL)
          .toPromise()
          .then(
              res => { // Success
              this.restaurant = res;
              console.log(this.restaurant.restaurantId);
                resolve();
              },
              msg => { // Error
                reject(msg);
              }
          );
    });
    return promise;
  }
}

  
