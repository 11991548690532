import { Waiter } from "./waiter.model";
import { Restaurant } from "./restaurant.model";

export class Table {
    id:number;
    tableNumber: string;
    table_capacity: number;
    activeFlag: boolean;
    activeFlagString: string;
    qrCodeUrl:String;
    waiter:Waiter;
    selectedWaiter:string;
    restaurant:Restaurant;
    
    constructor(){
      this.tableNumber ='';
      this.table_capacity;      
      this.activeFlag = true;
      this.qrCodeUrl = '';
     // this.waiter = new Waiter();
      this.selectedWaiter = '';
    }

}
